<script lang="ts" setup>
definePageMeta({
  layout: 'unauthenticated',
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/',
  },
})

const { $i18n } = useNuxtApp()
useHead({ title: `${$i18n.t('auth.login.title')} - ${$i18n.t('app.title')}` })

const { signIn } = useAuth()

const formData = ref<{ email: string, password: string }>({
  email: '',
  password: '',
})

const submitError = ref<boolean>(false)
const isLoading = ref<boolean>(false)

const submitHandler = async () => {
  isLoading.value = true
  formData.value.email = formData.value.email.toLowerCase()
  const res = await signIn('credentials', { ...formData.value, redirect: false })

  if (res?.error) {
    submitError.value = true
    isLoading.value = false
  } else {
    submitError.value = false
    return navigateTo(res?.url, { external: true })
  }
}

const institutionSelfRegister = useRuntimeConfig().public.institutionSelfRegister
</script>

<template>
  <TheDataCard
    :title="$t('auth.login.title')"
    header-class="bg-gray-200 !justify-center"
    class="max-w-lg mx-auto"
  >
    <n-alert v-if="submitError" :title="$t('auth.login.error.title')" type="error" class="mb-4">
      {{ $t('auth.login.error.text') }}
    </n-alert>

    <FormKit
      v-model="formData"
      type="form"
      :actions="false"
      incomplete-message=" "
      @submit="submitHandler"
    >
      <div class="flex flex-col gap-2">
        <FormKit
          name="email"
          type="text"
          :label="$t('auth.emailOrPhone.label')"
          :validation-label="$t('auth.emailOrPhone.placeholder')"
          :placeholder="$t('auth.emailOrPhone.placeholder')"
          validation="required"
          :disabled="isLoading"
          :classes="{
            outer: '$reset mb-2 w-full',
            input: 'w-full',
          }"
        />
        <FormKitPassword
          name="password"
          :label="$t('account.password.label')"
          :validation-label="$t('account.password.label')"
          :placeholder="$t('account.password.label')"
          :disabled="isLoading"
          validation="required"
          :classes="{
            outer: '$reset mb-2',
            input: 'w-full',
          }"
        />
      </div>
      <NuxtLink to="/auth/reset" class="text-sm hover:underline">
        {{ $t('auth.forgotPassword') }}
      </NuxtLink>
      <n-divider />
      <n-button type="primary" size="large" attr-type="submit" :disabled="isLoading" :loading="isLoading" class="w-full">
        {{ $t('button.login') }}
      </n-button>
    </FormKit>
    <NuxtLink :to="{ path: '/auth/register', query: { type: 'citizen' } }">
      <n-button secondary size="large" class="w-full mt-2">
        {{ $t('button.register.citizen') }}
      </n-button>
    </NuxtLink>
    <NuxtLink v-if="institutionSelfRegister" :to="{ path: '/auth/register', query: { type: 'institution' } }">
      <n-button secondary size="large" class="w-full mt-2">
        {{ $t('button.register.institution') }}
      </n-button>
    </NuxtLink>
  </TheDataCard>
</template>
